@font-face {
  font-family: 'JetBrains Mono Medium';
  src: url('../fonts/JetBrainsMono-Medium.eot');
  src: url('../fonts/JetBrainsMono-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/JetBrainsMono-Medium.woff2') format('woff2'),
  url('../fonts/JetBrainsMono-Medium.ttf') format('ttf'),
  url('../fonts/JetBrainsMono-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}