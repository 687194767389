@import "./node_modules/bootstrap/scss/bootstrap-reboot";
@import "./node_modules/bootstrap/scss/bootstrap-grid";
@import "fonts";
@import './_vars.scss';

body {
  font-family: $medium;
  font-size: 16px;
}

header {
  padding-top: 5px;

  .nav__links {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      font-family: $medium;
      color: #ffffff;
    }
  }

  .image__wrapper {

    img {
      width: 160px;
      height: auto;

      @include media-breakpoint-down(sm) {
        width: 109px;
      }
    }

    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }

  .col-lg-4 {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.main {
  background: url("../images/main_bg.jpg") center right no-repeat;
  background-size: cover;
  outline: none;

  @include media-breakpoint-down(xs) {
    background-position-x: 50%;
  }

  h1 {
    margin: 160px 0 180px 0;
    padding-left: 80px;
    font-size: 65px;
    color: white;
    line-height: 80px;

    br {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    @include media-breakpoint-down(md) {
      text-align: center;
      padding-left: 0;
    }
    @include media-breakpoint-down(xs) {
      font-size: 40px;
      line-height: 49px;
    }
  }

  .col-lg-7 {
    @include media-breakpoint-down(lg) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

}

.present {
  padding: 120px 0;

  .col-lg-6 {
    @include media-breakpoint-down(md) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  h2 {
    font-size: 30px;
    color: #00A4DA;
    padding-left: 80px;
    margin-bottom: 0;
    line-height: 37px;

    br {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    @include media-breakpoint-down(md) {
      padding-left: 0;
      text-align: left;
    }
  }
  p {
    margin-bottom: 0;
    line-height: 28px;

    @include media-breakpoint-down(md) {
      margin-top: 30px;
      text-align: left;
    }
  }
}
.section__title {
  font-size: 30px;
  text-align: center;
  margin-bottom: 70px;
}

.push__box {
  text-align: center;

  @include media-breakpoint-down(md) {
    margin-bottom: 30px;
  }

}

.box__image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 133px;
}

.box__descr {
  padding: 0 10px;
  line-height: 24px;
}

.push {
  padding: 120px 0;
  background: #F0F0F0;
  outline: none;
}

.media {
  background: url("../images/media_bg.jpg") bottom center no-repeat;
  background-size: cover;
  padding: 156px 0 60px 0;

  @include media-breakpoint-down(md) {
    padding: 90px 0 90px 0;
  }
  @include media-breakpoint-down(sm) {
   background-position-x: 70% ;
  }

  p {
    margin: 0;
    line-height: 28px;
    color: white;
    padding-left: 80px;

    @include media-breakpoint-down(md) {
      padding-left: 0;
    }

  }
}

.tool__image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 157px;
}

.tool__box {
  text-align: center;
  @include media-breakpoint-down(md) {
    margin-bottom: 30px;
  }
}

.tool__descr {
  padding: 0 10px;
  line-height: 24px;

  span {
    display: block;
    margin-top: 30px;
    font-style: italic;
  }
}

.tools {
  padding: 120px 0 85px 0;
}

.vision {
  background: #F0F0F0;
  padding: 124px 0 128px 0;

  p {
    margin: 0;
    padding-left: 80px;
    line-height: 28px;

    @include media-breakpoint-down(md) {
      padding-left: 0;
      text-align: left;
    }
  }

  h3 {
    margin: 0;
    font-size: 30px;
    color: #00A4DA;
    padding-left: 80px;
    line-height: 37px;

    @include media-breakpoint-down(md) {
      padding-left: 0;
      text-align: left;
      margin-top: 30px;
    }
  }
}

footer {
  background: url("../images/footer_bg.jpg") right center no-repeat;
  background-size: cover;
  padding: 70px 0 44px 0;
  outline: none;

  .col-lg-7 {
    padding-left: 80px;

    @include media-breakpoint-down(md) {
      padding-left: .9375rem;
    }
  }
}

.top__link {
  color: #00A4DA;
  &:hover {
    color: #00A4DA;
    text-decoration: none;
  }
}
.description {
  margin-bottom: 0;
  color: #8A8A8A;
  margin-top: 50px;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.info {
  padding-left: 80px;

  @include media-breakpoint-down(md) {
    padding-left: 0;
    margin-top: 30px;
    display: flex;
  }

  p {
    color: white;
  }

  .info__links {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(md) {
      margin-left: 60px;
    }

    a {
      &:first-child {
        color: #00A4DA;
        &:hover {
          text-decoration: none;
          color: #00A4DA;
        }
      }
      &:last-child {
        color: #8A8A8A;
        margin-top: 5px;
        &:hover {
          text-decoration: none;
          color: #8A8A8A;
        }
      }
    }
  }
}
.m-description {
  display: none;
  margin-bottom: 0;
  color: #8A8A8A;

  @include media-breakpoint-down(sm) {
    display: block;
    margin-top: 40px;
  }

}